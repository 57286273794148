/* general styles */
@import url('./vendor/normalize.css');
@import url('./vendor/fonts/fonts.css');

@import url('./components/general/page.css');
@import url('./components/general/root.css');
@import url('./components/general/content.css');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
