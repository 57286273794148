.movies {
    padding: 0 70px;
}

@media (max-width: 768px) {
    .movies {
        padding: 0 30px;
    }
}

@media (max-width: 540px) {
    .movies {
        padding: 0 10px;
        min-height: 100%;
    }
}
