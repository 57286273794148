.not-found_page {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
}

.not-found_info__wrap {
    margin: 246px 0 0;
}

.not-found_page__err {
    font-size: 140px;
    font-weight: 400;
    line-height: 170px;
    margin: 0;
}

.not-found_page__text {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    margin: 5px 0 0;
    text-align: center;
}

.link-back {
    text-decoration: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #3456F3;
    margin-bottom: 60px;
}

@media (max-width: 768px) {
    .not-found_info__wrap {
        margin: 408px 0 0;
    }
    .not-found_page {
        min-height: 1024px;
        justify-content: flex-start;
    }
    .link-back {
        margin: 185px 0 0;
    }
}

@media (max-width: 540px) {
    .not-found_page {
        min-height: 780px;
    }
    .not-found_page__err {
        font-size: 80px;
        line-height: 97px;
    }
    .not-found_info__wrap {
        margin: 329px 0 0;
    }
    .not-found_page__text {
        font-size: 12px;
        line-height: 14.5px;
        margin: 12px 0 0;
    }
    .link-back {
        font-size: 12px;
        margin: 282px 0 0;
    }
}
