.form-sec {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
}

.form-sec__box_profile {
    min-width: 410px;
    min-height: 624px;
}

.form-sec__title_type_profile {
    display: inline-block;
    text-align: center;
    padding-top: 74px;
}

.form {
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.profile__inputs {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height:  185px;
}

.profile__input-wrap {
    padding-top: 17px;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row-reverse;
    position: relative;
    border-bottom: 1px solid #E8E8E8;
    transition: border-bottom .3s ease;
}

.profile__input-wrap:first-of-type {
    padding-top: 123px;
}
.profile__input-wrap:nth-child(2) {
    border-bottom: none;
}

.profile__input-label {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 11px;
    color: #000;
    line-height: 13.3px;
    height: 100%;
    width: 20%;
    position: relative;
    /*padding-top: 16px;*/
}

.profile__input-label:first-child {
    /*padding-top: 123px;*/
}

.profile__input {
    font-weight: 400;
    font-size: 11px;
    line-height: 17px;
    color: #000;
    border: 0;
    outline: 0;
    text-align: right;
    width: 80%;
    height: 100%;
    padding: 0;
}

.profile__input:focus + .profile__input-label::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 410px;
    height: 100%;
    color: #A0A0A0;
    border-bottom: 1px solid #3456F3;
}

.profile__input-err {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #FF3055;
    padding-top: 4px;
    position: absolute;
    bottom: -1px;
    right: 0;
}

.caption_profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
}

.caption__message {
    text-decoration: none;
    font-weight: 900;
    font-size: 13px;
    line-height: 15.7px;
    margin: 0;
    display: block;
    color: #028a02;
    padding: 0;
    position: absolute;
    top: -30px;
}

.caption__text_profile {
    text-decoration: none;
    font-weight: 500;
    font-size: 13px;
    line-height: 15.7px;
    margin: 0;
    display: block;
    color: #FF3055;
    padding: 16px 0 67px;
    transition: opacity .4s linear;
}

.caption__text_btn {
    border: none;
    font-weight: 400;
    color: #000;
    background-color: transparent;
    text-align: center;
    padding: 0;
    margin: 0 auto;
    cursor: pointer;
}

.caption__text_btn-logout {
    color: #FF3055;
    font-weight: 500;
    padding: 16px 0 67px;
}

.btn_entry {
    width: 100%;
    min-height: 45px;
    background-color: #3456F3;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #FFF;
}

.btn_entry_profile {
    margin-bottom: 67px;
}

.btn_entry_profile_disabled {
    background-color: #F8F8F8;
    color: #C2C2C2;
    cursor: not-allowed;
}

@media (max-width: 768px) {
    .form-sec__title_type_profile {
        padding-top: 236px;
    }
    .profile__input-wrap:first-of-type {
        padding-top: 97px;
    }
    .profile__inputs {
        min-height: 177px;
    }
    .profile__input-err {
        padding-top: 7px;
    }
    .caption_profile {
        padding: 192px 0 269px;
    }
    .caption__message {
        top: 150px;
    }
    .caption__text_btn-logout {
        padding: 16px 0 0;
    }
    .btn_entry_profile {
        margin-top: 192px;
        margin-bottom: 271px;
    }

}

@media (max-width: 540px) {
    .form-sec__box_profile {
         min-width: 260px;
    }
    .form-sec__title_type_profile {
        padding-top: 70px;
    }
    .profile__input-wrap:first-of-type {
        padding-top: 81px;
    }
    .profile__input-err {
        padding-top: 5px;
        bottom: -11px;
    }
    .profile__input:focus + .profile__input-label::after {
        width: 260px;
    }
    .caption_profile {
        padding: 344px 0 40px;
    }
    .caption__message {
        top: 310px;
    }
    .caption__text {
        font-size: 12px;
    }
    .btn_entry_profile {
        font-size: 12px;
        margin-top: 345px;
    }
}
