.search_form {
    min-height: 217px;
}

.search__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E8E8E8;
    padding: 70px 0 13px;
}

.search__input {
    border: none;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    color: #A0A0A0;
    width: 100%;
    padding: 0;
}

.search__input:focus {
    color: #A0A0A0;
    background-color: #FAFAFCFF;
    border-color: #FFF;
    outline: 0;
    box-shadow: 0 0 10px rgba(133, 133, 133, 0.2);
}

.search__btn {
    height: 34px;
    background-color: #3456F3;
    border: none;
    cursor: pointer;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 0.4px;
    color: #FFF;
    text-align: center;
    padding: 8px 11.35px;
    border-radius: 25px;
    transition: opacity .4s linear;
}

.search__btn:hover {
    opacity: .7;
}

@media (max-width: 768px) {
    .search_form {
        /*padding: 0 14px;*/
        min-height: 100%;
    }
    .search__wrap {
        padding-top: 80px;
    }
}

@media (max-width: 540px) {
    .search_form {
        padding: 0 4px;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .search__input {
        font-size: 18px;
        line-height: 22px;
    }
    .search__wrap {
        padding: 85px 0 9px;
    }
}
