.burger,
.overlay__close,
.link {
    display: none;
}

@media (max-width: 768px) {
    .burger {
        display: block;
        width: 44px;
        height: 44px;
        margin: 0;
        padding: 0;
        border: none;
        background-color: inherit;
        position: absolute;
        top: 13px;
        right: 30px;
        z-index: 1;
        transition: all .4s linear;
    }

    .burger:before {
        content: '';
        background-image: url('../../../images/burger_black.svg');
        background-repeat: no-repeat;
        display: block;
        width: 40px;
        height: 40px;
    }

    .burger:hover {
        cursor: pointer;
        opacity: .8;
    }

    .burger_type_land:before {
        filter: invert(100%);
    }

    .overlay {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0.4s, opacity 0.4s linear;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.3);
        height: 1024px;
    }

    .overlay_opened {
        visibility: visible;
        opacity: 1;
    }

    .overlay__close {
        display: block;
        background-image: url('../../../images/close_icon.svg');
        background-repeat: no-repeat;
        background-color: transparent;
        width: 22px;
        height: 22px;
        background-position: center;
        border: none;
        position: absolute;
        top: 26px;
        right: 26px;
        z-index: 3;
        cursor: pointer;
        transition: opacity .4s linear;
    }

    .overlay__close:hover {
        opacity: .8;
    }

    .overlay__container {
        width: 68vw;
        background: #FFF;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.35);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 159px 0 86px;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        height: 100%;
    }

    .links_wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px 0;
        position: relative;
    }

    .link {
        display: flex;
        color: #000;
        text-decoration: none;
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        padding-bottom: 4px;
        border-bottom: 2px solid #FFF;
        transition: opacity .4s linear;
    }

    .link_active {
        text-decoration: none;
        border-bottom: 2px solid #000;
        line-height: 23px;
    }

    .link_type_account {
        border-bottom: none;
    }

    .link:hover {
        opacity: .7;
    }

}

@media (max-width: 540px) {
    .overlay {
        height: 100%;
    }
    .overlay_opened {
        visibility: visible;
        opacity: 1;
    }
    .burger {
        top: 16px;
        right: 16px;
        width: 38px;
    }
    .burger:before {
        background-size: 38px;
        width: 30px;
        height: 40px;
    }
    .overlay__container {
        width: 100vw;
        padding: 124px 0 42px;
        height: 780px;
    }
    .overlay__close {
        top: 18px;
        right: 18px;
    }
    .links_wrap {
        gap: 0;
    }
    .link {
        line-height: 22px;
        margin-top: 19px;
    }
    .link_active {
        line-height: 24px;
    }

}
