.login__input-label {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 10px;
    color: #A0A0A0;
    padding-top: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid #E8E8E8;
}

.login__input-label:first-of-type {
    padding-top: 40px;
    padding-bottom: 10px;
    border-bottom: 1px solid #E8E8E8;
}

.login__input {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000;
    padding-top: 5px;
    border: 0;
}

.login__input:focus {
    color: #A0A0A0;
    border-color: #FFF;
    outline: 0;
    box-shadow: 0 0 10px rgb(155, 159, 159, .5);
}

.login__input:focus:invalid {
    box-shadow: 0 0 10px rgb(255, 48, 85, .5);
}

.login__input:focus:valid {
    box-shadow: 0 0 10px rgb(0, 128, 0, .5);
}

.login__input-err {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #FF3055;
    padding-top: 4px;
}

.btn_entry_login {
    margin-top: 110px;
}
.btn_entry_login:disabled {
    background-color: #F8F8F8;
    color: #C2C2C2;
    cursor: not-allowed;
}

@media (max-width: 768px) {
    .login__input-label {
        height: 45px;
        padding-bottom: 0;
    }
    .login__input-label:first-of-type {
        /*height: 45px;*/
        padding-bottom: 0;
    }
    .login__input-err {
        padding-top: 7px;
    }
    .btn_entry_login {
        margin-top: 110px;
    }
}

@media (max-width: 540px) {
    .login__input-label:first-child {
        padding-top: 0;
    }
    .form-sec__title_type_login {
        padding: 50px 0 80px;
    }
    .login__input-label > input:first-of-type {
        padding-bottom: 10px;
    }
    .login__input-label {
        padding-top: 25px;
    }
    .login__input-err {
        padding-top: 5px;
    }
    .btn_entry_login {
        margin-top: 159px;
        font-size: 12px;
    }
}
