.logo {
    transition: opacity .4s linear;
}

.logo:hover {
    opacity: .7;
}

.form-sec {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
}

.form-sec__box {
    min-width: 396px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.form-sec__logo {
    width: 38px;
    height: 38px;
    padding-top: 70px;
}

.form-sec__title {
    margin: 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    padding-top: 40px;
}

.form {
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.form__inputs {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 265px;
}

.btn__api-err {
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: #FF3055;
    position: absolute;
    width: 100%;
    top: -35px;
    left: 0;
}

.btn {
    border: none;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: opacity .4s linear;
    padding: 0;
}

.btn:hover {
    opacity: .8;
}

.btn_entry {
    width: 100%;
    min-height: 45px;
    background-color: #3456F3;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #FFF;
    position: relative;
}

.caption_register,
.caption_login {
    display: block;
    text-align: center;
    padding: 16px 0 70px;
}

.caption__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #A0A0A0;
    margin: 0;
    display: inline-block;
    padding-right: 6px;
}

.caption__link {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #3456F3;
    text-decoration: none;
    display: inline-block;
    transition: opacity .4s linear;
}

.caption__link:hover {
    opacity: .7;
}

@media (max-width: 768px) {
    .form-sec__box_register {
        padding-top: 232px;
    }
    .form-sec__box_login {
        padding-top: 232px;
    }
    .form-sec__logo {
        padding-top: 0;
    }

    .caption__text {
        padding-bottom: 0;
    }
    .caption_register,
    .caption_login {
        padding-bottom: 232px;
    }
}

@media (max-width: 540px) {
    .form-sec__box {
        min-width: 260px;
        max-width: 260px;
    }
    .form-sec__box_register {
        padding-top: 56px;
    }
    .form-sec__box_login {
        padding-top: 56px;
    }
    .form-sec__logo {
        padding-top: 0;
        align-self: center;
    }
    .form-sec__title {
        text-align: center;
        padding: 70px 0 0;
    }
    .form-sec__title_type_profile {
        padding-top: 70px;
    }
    .form-sec__title_type_register {
        padding-top: 50px;
    }
    .form-sec__title_type_login {
        padding-top: 50px;
    }
    .form-sec__title_type_register {
        padding: 50px 0 80px;
    }
    .btn_entry {
        font-size: 12px;
    }
    .caption_register,
    .caption_login {
        padding: 11px 0 30px;
    }
    .caption__text:first-child {
        padding-right: 6px;
    }
    .caption__text {
        font-size: 12px;
    }
    .caption__link {
        font-size: 12px;
    }

}
