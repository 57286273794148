.promo {
    background-color: #073042;
    display: flex;
    justify-content: space-between;
    min-height: 574px;
}

.promo:after {
    content: '';
    display: block;
    min-width: 310px;
    min-height: 304px;
    margin-top: 75px;
    background: url('../../../images/logo-world.png') no-repeat right top;
    background-size: contain;
}

.promo__wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 75px 0 0;
}

.promo__wrap-text {
    display: flex;
    flex-direction: column;
}

.promo__header {
    color: #FFF;
    max-width: 640px;
    font-size: 50px;
    font-weight: 400;
    line-height: 58px;
    margin: 0;
}

.promo-white-space {
    white-space: nowrap;
}

.promo__text {
    max-width: 310px;
    font-weight: 400;
    font-size: 13px;
    line-height: 15.7px;
    color: #FFF;
    opacity: .7;
    margin: 0;
    padding: 36px 0 0 0;
}

.promo__link {
    width: 129px;
    min-height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 1px solid #FFF;
    border-radius: 3px;
    margin: 0 0 75px 0;
    text-decoration: none;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #FFF;
    transition: opacity .4s linear;
}

.promo__link:hover {
    opacity: .7;
}

@media (max-width: 900px) {
    .promo {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: flex-end;
        min-height: 850px;
    }
    .promo:after {
        margin-top: 140px;
    }
    .promo__wrap {
        align-items: center;
        margin: 48px 0 0;
    }
    .promo__wrap-text {
        align-items: center;
    }
    .promo__header {
        text-align: center;
        font-size: 40px;
        line-height: 50px;
        max-width: 680px;
    }
    .promo__text {
        text-align: center;
        padding-top: 22px;
    }
    .promo__link {
        margin-top: 93px;
    }

}

@media (max-width: 540px) {
    .promo {
        padding: 0 8px;
        min-height: 636px;
    }
    .promo__wrap {
        margin: 44px 0 0;
    }
    .promo:after {
        min-width: 210px;
        min-height: 204px;
        margin-top: 71px;
    }

    .promo__header {
        font-size: 30px;
        line-height: 38px;
        max-width: 300px;
        letter-spacing: -1px;
    }
    .promo__text {
        padding-top: 24px;
    }
    .promo__link {
        margin: 80px 0 30px;
    }
}
